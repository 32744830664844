import * as React from "react"
import PropTypes from "prop-types"

import OnGoing from "./ongoing";
import Label from "../shared/label";
import Conf, {ConfItem, ConfRow} from "../shared/conf";
import { MarkerIco, TimeIco } from "../shared/icos";


const List = (props) => {
    const { confs, isOver } = props;
    let active;
    if (confs)
        active = confs.shift();
    else
        active = null;

    return (
        <>
            <OnGoing isOver={isOver} conf={active}/>

            { confs && confs.length > 0 && (
                <div className={`mt-6 p-10 pb-2 rounded-6.25 border-0.25 border-blight-gray`}>
                    <div className={`mb-2`}>
                        <Label variation={'gray'}>Plus tard dans la journée</Label>
                    </div>

                    <ul className={`divide-y divide-blight-gray`}>
                        { confs.map((conf, index) => (
                            <li key={index} className={`py-8`}>
                                <Conf>
                                    <h3 className={`text-black text-xl`}>{ conf.title }</h3>

                                    <ConfRow classes={`mt-2 space-x-6 text-borange`}>
                                        <ConfItem classes={`flex-none`} picto={<TimeIco color={'#FC8A58'} classes={`flex-none mr-2 w-6 h-6`}/>} text={`${conf.startHour}:${conf.startMinutes} - ${conf.endHour}:${conf.endMinutes}`}/>
                                        <ConfItem classes={`flex-1`} picto={<MarkerIco color={'#FC8A58'} classes={`flex-none mr-2 w-6 h-6`}/>} text={conf.forum}/>
                                    </ConfRow>
                                </Conf>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}

List.propTypes = {
    full: PropTypes.bool.isRequired,
    confs: PropTypes.array,
    isOver: PropTypes.bool.isRequired,
}

export default List
